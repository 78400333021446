var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['c-toasts__item']
  }, [_c('span', {
    staticClass: "h-full w-2 inline-block absolute left-0",
    class: ['c-toasts__item--' + _vm.toast.type]
  }, [_vm._v(" ")]), _c('div', {
    staticClass: "flex items-center gap-4 pl-6 pr-6"
  }, [_c(_vm.toast.icon, {
    tag: "component"
  }), _c('span', {
    staticClass: "c-toasts__item-text py-4 pr-4 bg-white text-left"
  }, [_vm.toast.primaryText ? _c('p', {
    staticClass: "text-neutral-700 font-medium",
    domProps: {
      "innerHTML": _vm._s(_vm.toast.primaryText)
    }
  }) : _vm._e(), _vm.toast.secondaryText ? _c('p', {
    staticClass: "text-neutral-500 text-sm"
  }, [_vm._v(_vm._s(_vm.toast.secondaryText))]) : _vm._e()])], 1), _c('span', {
    staticClass: "inline-block absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer",
    on: {
      "click": function ($event) {
        return _vm.close(_vm.toast.id);
      }
    }
  }, [_c('Close', {
    attrs: {
      "width": "28",
      "height": "28"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }